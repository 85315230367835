import React from 'react';
import { Box, Stack, Skeleton } from '@mui/material';

function AssistantsSkeleton() {
    return (
        <Box>
            <Stack
                direction={'column'}
                alignItems={"center"}
                spacing={2}
            >
                <Skeleton
                    sx={{ padding: '8px' }}
                    width={"99%"}
                    height={"350px"}
                    animation="wave"
                    variant="rectangular"
                />
                <Skeleton
                    sx={{ padding: '8px' }}
                    width={"99%"}
                    height={"350px"}
                    animation="wave"
                    variant="rectangular"
                />
            </Stack>
        </Box>
    )
}

export default AssistantsSkeleton
