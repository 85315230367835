import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { AppBar, Box, Stack } from "@mui/material";
import { ProfilePopper, SupportPopper } from "./components";
import thirdvoiceLogo from "../../assets/logos/thirdvoice_horizontal.png";
import "./navbar.scss"; 

export const NAVBAR_HEIGHT = "56px";

export default function Navbar () {
    const { orgId } = useParams();

    return (
        <AppBar 
            id="navbar"
            position="sticky"
            sx={{
                height: NAVBAR_HEIGHT,
                px: 2,
                boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.05)",
                "&.MuiPaper-root.MuiAppBar-root": {
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }
            }}
        >
            <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                height="100%"
            >
                <Box>
                    <Link to={`/org/${orgId}/overview`}>
                        <img className="thirdvoice-logo" src={thirdvoiceLogo} alt="Thirdvoice Logo" />
                    </Link>
                </Box>

                <Stack
                    direction="row"
                    spacing={1.5}
                    alignItems="center"
                    height="100%"
                >
                    <SupportPopper />
                    <ProfilePopper />
                </Stack>
            </Stack>
        </AppBar>
    );
};