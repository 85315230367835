import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, Fade, Icon, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import { PlayCircleOutlineRounded } from "@mui/icons-material";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { setCallDetails } from "./callLogsSlice";
import { fetchCallLogs } from "./apiFunctions";
import { getLeadStateColor } from "./utilFunctions";
import { CallDetails } from "./components";
import { TRANSITION_TIME_OUT } from "../../utils/constants";
import "./callLogs.scss";
import { FILTER_SLICE_KEY, setFilterData } from "../../components/filters/filterSlice";
import { Filters } from "../../components";
import { getAssistantsList } from "../assistants/apiFunctions";
import { setDateParams } from "../../components/filters/utilFunctions";
import { OUTLET_HEIGHT } from "../../routes/privateRoutes";

function LogCellSkeleton () {
    return (
        <Skeleton variant="text" width={"50%"} height={24} animation="wave" />
    ); 
}

export default function CallLogs() {
    const { orgId } = useParams();
    const tableHeaders = ["Lead", "Caller Name", "Phone No.", "Date & Time", "Listen", "Status"];

    const [callLogs, setCallLogs] = useState(null);
    const [loading, setLoading] = useState(false);
    const [assistant, setAssistant] = useState([]);

    const navigate = useNavigate();  
    const { id } = useParams();

    const dispatch = useDispatch();
    const { appliedFiltersParams, appliedDateRangeParams } = useSelector(state => state[FILTER_SLICE_KEY]);

    const getCallDuration = (startedAt, endedAt, index) => {
        let start = moment(startedAt);
        let end = moment(endedAt);

        let duration = moment.duration(end.diff(start));

        let hours = duration.hours();
        let minutes = duration.minutes();
        let seconds = duration.seconds();

        if (hours === 0 && minutes === 0 && seconds === 0) {
            return null;
        }

        return `${hours ? hours + "h" : ""} ${minutes ? minutes + "m" : ""} ${seconds ? seconds + "s" : ""}`;
    };

    const onGetCallLogsSuccess = (data) => {
        setCallLogs(data);
        setLoading(false);
    };

    const onGetCallLogsError = (error) => {
        toast.error("Unable to fetch call logs");
        setLoading(false);
    };

    const getCallLogs = (dateParams) => {
        setLoading(true);

        let params = { 
            // orgId: orgId,
            limit: 1000,
            isActive: true,
        };

        if (dateParams) {
            params = { ...params, ...dateParams };
        }

        if (appliedFiltersParams) {
            params = { ...params, ...appliedFiltersParams };
        }

        fetchCallLogs(params, { orgId: orgId }, onGetCallLogsSuccess, onGetCallLogsError);
    };

    const openCallDetailsModal = (log) => {
        dispatch(setCallDetails({ openCallDetails: true, currentCallDetails: log }));
        navigate(`/org/${orgId}/call-logs/${log.id}`);
    };

    // get assistants list for filter
    useEffect(() => {
        getAssistantsList(
            {},
            {orgId: orgId},
            (data) => setAssistant(data),
            (error) => console.log("ERROR", error)
        );
    }, [orgId]);

    // set filter options
    useEffect(() => {
        let filterOptions = [
            {
                id: 1, 
                name: "assistantId",
                title: "Assistants",
                type: "select",
                options: assistant.map((item, index) => ({ id: index, label: item.name, value: item.id }))
            },
        ];

        dispatch(setFilterData(filterOptions));
    }, [assistant]);

    useEffect(() => {
        const dateParams = setDateParams(appliedDateRangeParams, "YYYY-MM-DD", "fromDate", "toDate");
        getCallLogs(dateParams);
    }, [appliedDateRangeParams, appliedFiltersParams, orgId]);

    useEffect(() => {
        if (id && callLogs) {
            let call = callLogs.find(log => log.id === id);
            if (call) {
                openCallDetailsModal(call);
            }
        }
    }, [callLogs, id]);

    return (
        <Box id="call-logs" width="100%">
            <Paper 
                className="call-logs-wr"
                elevation={0}
                component={Stack}
                direction="column"
                height={OUTLET_HEIGHT}
                width="100%"
            >
                <Toolbar className="cl-toolbar">
                    <Fade in={true} timeout={TRANSITION_TIME_OUT}>
                        <Stack
                            direction="column"
                            spacing={3}
                            width="100%"
                        >
                            <Typography component="h2" variant="h6" fontWeight={500}>
                                Call Logs
                            </Typography>

                            {/* filters */}
                            <Filters showDateRangeFilter={true} />

                        </Stack>
                    </Fade>
                </Toolbar>
                
                <TableContainer className="cl-table-cn">
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {
                                    tableHeaders.map((header, index) => (
                                        <TableCell key={index}>{header}</TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                callLogs && !loading ? (
                                    callLogs?.length > 0 ? (
                                        callLogs.map((log, index) => (
                                            <Fade in={true} timeout={TRANSITION_TIME_OUT} key={index}>
                                                <TableRow
                                                    className="cl-table-row"
                                                    key={index}
                                                    hover
                                                    onClick={() => openCallDetailsModal(log)}
                                                >
                                                    <TableCell>
                                                        {
                                                            log.leadStatus ? 
                                                            (
                                                                <Chip
                                                                    label={(log.leadStatus).split(" ")[0]}
                                                                    variant="outlined"
                                                                    color={getLeadStateColor(log.leadStatus)}
                                                                />
                                                            ) : 
                                                            "--"
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {log.callerName ? log.callerName : "--"}
                                                    </TableCell>
                                                    <TableCell>
                                                        {log.customer?.number ? log.customer.number : "--"}
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            log.createdAt ? 
                                                                <Stack direction="column" spacing={0.5}>
                                                                    <Typography variant="body1">{moment(log.createdAt).format("MMM D, YYYY")}</Typography>
                                                                    <Typography variant="body1">{moment(log.createdAt).format("hh:mm A")}</Typography>
                                                                </Stack> : 
                                                                "--"
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            getCallDuration(log.startedAt, log.endedAt, index) ? (
                                                                <Stack
                                                                    direction="column"
                                                                    spacing={0.5}
                                                                >
                                                                    <Icon color="primary">
                                                                        <PlayCircleOutlineRounded />
                                                                    </Icon>
                                                                    <Typography variant="body2">
                                                                        {getCallDuration(log.startedAt, log.endedAt, index)}
                                                                    </Typography>
                                                                </Stack>
                                                            ) : (
                                                                <Typography variant="body2">--</Typography>
                                                            )
                                                        }
                                                        
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body1" textTransform="capitalize">
                                                            {log.status ? log.status : "--"}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </Fade>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={tableHeaders.length} align="center">No call logs found!</TableCell>
                                        </TableRow>
                                    )
                                ) : (
                                    new Array(10).fill(0).map((_, index) => (
                                        <TableRow key={index}>
                                            {
                                                tableHeaders.map((_, index) => (
                                                    <TableCell key={index}>
                                                        <LogCellSkeleton />
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <CallDetails />
        </Box>
    );
}
