import React, { useEffect, useState } from 'react';
import { Stack, Box, Fade, Typography } from '@mui/material';
import { getAssistantsList } from './apiFunctions';
import { AssistantsCard, AssistantsSkeleton } from './components';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';

function Assistants() {
    const { orgId } = useParams();
    
    const [assistantsList, setAssistantsList] = useState([]);
    const [activeAssistantsList, setActiveAssistantsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const onFetchAssistantsSuccess = (data) => {
        setAssistantsList(data);
        setIsLoading(false);
    }

    const onFetchAssistantsError = (error) => {
        toast.error("Error occurred while fetching assistants!");
        setIsLoading(false);
    }

    const fetchAssistants = async () => {
        setIsLoading(true);
        getAssistantsList(
            {isActive: true},
            {orgId: orgId},
            onFetchAssistantsSuccess,
            onFetchAssistantsError
        );
    }

    useEffect(() => {
        fetchAssistants();
    }, [orgId]);

    // seeting active assistant list manually (awaiting changes from backend)
    useEffect(() => {
        setActiveAssistantsList(assistantsList.filter(assistant => assistant.isActive));
    }, [assistantsList]);


    if (isLoading) {
        return <AssistantsSkeleton />
    }

    return (
        <Fade
            in={true}
            timeout={2500}
        >
            <Box>
                <Stack
                    direction={'column'}
                    spacing={2}
                >
                    {
                        activeAssistantsList.length === 0 ? (
                            <Box>
                                <Typography variant='body1' color="grey.600">No active assistants found for this organization!</Typography>
                            </Box>
                        ) : (
                            activeAssistantsList.map((assistant, idx) => (
                                <AssistantsCard index={idx + 1} key={assistant.id} data={assistant} />
                            ))
                        )
                    }
                </Stack>
            </Box>
        </Fade>
    )
}

export default Assistants
