
// AUTH
export const LOGIN = '/v1/user/login';
export const VERIFY_OYP = '/v1/user/verifyOtp';

// ORGANIZATIONS
export const USER_ORGS = '/v1/user/userOrgs';
export const ORG_DETAILS = '/v1/org/:orgId';
export const UPDATE_ORG_DETAILS = '/v1/org/update';

// SIDENAV
export const SIDENAV_CONFIG = '/v1/config/sidenav';

// Overview
export const OVERVIEW_SECTION_1 = '/v1/dashboard/section1';
export const OVERVIEW_SECTION_2 = '/v1/dashboard/section2';

// CALL LOGS
export const VAPI_CALL_LOGS = '/v1/vapi/calllogs';

// ASSISTANTS
export const ASSISTANTS_LIST = '/v1/vapi/assistants';

// KOLLA MARKETPLACE - CONNECT DENTAL PMS
export const MARKETPLACE_CONSUMER_TOKEN = '/v1/org/getMarketPlaceToken';
export const VALIDATE_KOLLA_INTEGRATION = '/v1/org/validateIntegration';
