import { useDispatch, useSelector } from "react-redux";
import { FILTER_SLICE_KEY, removeAppliedDateRange, removeAppliedFilter } from "./filterSlice";
import { Chip, Stack } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

export default function AppliedFilters({ showDateRange }) {
    const { appliedFilters, appliedDateRangeString } = useSelector((state) => state[FILTER_SLICE_KEY]);

    const dispatch = useDispatch();

    const removeDateRangeFilter = () => {
        dispatch(removeAppliedDateRange());
    }

    const removeFilter = (filterName) => {
        dispatch(removeAppliedFilter(filterName));
    }

    return (
        (appliedFilters || appliedDateRangeString) ? (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1.5}
                width="100%"
                style={{ marginLeft: "-10px" }}
            >
                {
                    (showDateRange && appliedDateRangeString) && (
                        <Chip
                            variant="outlined"
                            sx={{ color: "text.secondary" }}
                            label={appliedDateRangeString}
                            onDelete={removeDateRangeFilter}
                            deleteIcon={<CloseRounded style={{ fontSize: "16px" }} />}
                        />
                    )
                }
                {
                    appliedFilters && (
                        Object.keys(appliedFilters).map((filter) => (
                            appliedFilters[filter].map((option, index) => (
                                <Chip
                                    key={index}
                                    variant="outlined"
                                    sx={{ color: "text.secondary" }}
                                    label={option.label}
                                    onDelete={() => removeFilter(filter)}
                                />
                            ))
                        ))
                    )
                }
            </Stack>
        ) : null
    );
}