import Request from '../../utils/api';
import { LOGIN, VERIFY_OYP } from '../../utils/apiRoutes';
import { setCookie } from '../../utils/cookies';

const api = new Request();

export const getVerificationCode = async (postPayload, onSuccess, onError) => {
    const response = await api.post(LOGIN, postPayload, {});


    if (response.message === "Success" && response.data) {
        return typeof onSuccess === "function" ? onSuccess(response.data) : response.data;
    } else {
        return typeof onError === "function" ? onError(response) : response;
    }
};

export const verifyCode = async (postPayload, onSuccess, onError) => {
    const response = await api.post(
        VERIFY_OYP,
        postPayload,
        {}
    );

    if (response.message === "Success" && response.data) {
        if (response.data.login && response.data.token) {
            setCookie('accessToken', response.data.token, 1);
        }

        return typeof onSuccess === "function" ? onSuccess(response.data) : response.data;
    } else {
        return typeof onError === "function" ? onError(response.data) : response.data;
    }
};
