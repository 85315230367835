import axios from 'axios';
import { getCookie } from './cookies';
class Request {
	constructor() {
		this.baseURL = process.env.REACT_APP_BACKEND_URL;
		this.API_KEY = process.env.REACT_APP_BACKEND_KEY;
	}

	get headers() {
		let token = `Bearer ${getCookie('accessToken')}` || '';
		return {
			'api-key': this.API_KEY,
			Authorization: token,
			'Content-type': 'application/json',
		};
	}

	/**
	 * GET Request
	 * @param {String} URL
	 * @param {Object} params
	 * @returns
	 */
	async get(URL, params, headers) {
		let headersObj = headers ? { ...headers, ...this.headers } : this.headers;

		try {
			const { data } = await axios.get(
				this.baseURL + URL, 
				{ 
					params, 
					headers: headersObj 
				}
			);
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	async post(URL, paylaod, params, headers) {
		let headersObj = headers ? { ...headers, ...this.headers } : this.headers;

		try {
			const { data } = await axios.post(
				this.baseURL + URL, 
				paylaod, 
				{ 
					params,
					headers: headersObj
				}
			);
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	put(URL, params, paylaod) {}

	delete(URL, params, paylaod) {}
}

export default Request;
