import Request from '../../utils/api';
import { MARKETPLACE_CONSUMER_TOKEN, ORG_DETAILS, UPDATE_ORG_DETAILS, USER_ORGS, VALIDATE_KOLLA_INTEGRATION } from '../../utils/apiRoutes';

const api = new Request();

export const fetchUserOrgs = async (params, onSuccess, onError) => {
    const response = await api.get(USER_ORGS, params);

    if (response.message === "Success" && response.data) {
        return typeof onSuccess === "function" ? onSuccess(response.data) : response.data;
    } else {
        return typeof onError === "function" ? onError(response) : response;
    }
};

export const getOrgDetails = async (orgId, onSuccess, onError) => {
    const reqUrl = ORG_DETAILS.replace(":orgId", orgId);
    const response = await api.get(reqUrl);

    if (response.message === "Success" && response.data) {
        return typeof onSuccess === "function" ? onSuccess(response.data) : response.data;
    } else {
        return typeof onError === "function" ? onError(response) : response;
    }
};

export const updateOrgDetails = async (postPayload, onSuccess, onError) => {
    const response = await api.post(UPDATE_ORG_DETAILS, postPayload);

    if (response.message === "success" && response.status) {
        return typeof onSuccess === "function" ? onSuccess(response.status) : response.status;
    } else {
        return typeof onError === "function" ? onError(response) : response;
    }
};

export const getConsumerToken = async (headers, onSuccess, onError) => {
    const response = await api.post(MARKETPLACE_CONSUMER_TOKEN, {}, {}, headers);

    if (response.message === "success" && response.data) {
        return typeof onSuccess === "function" ? onSuccess(response.data) : response.data;
    } else {
        return typeof onError === "function" ? onError(response) : response;
    }
};

export const validateKollaIntegration = async (postPayload, onSuccess, onError) => {
    const response = await api.post(VALIDATE_KOLLA_INTEGRATION, postPayload);

    console.log(response);
};
