// all routes for the site

import { Navigate, Route, Routes, useLocation } from 'react-router';
import { Login, Overview, Assistants } from '../pages';
import CallLogs from '../pages/callLogs/CallLogs';
import PrivateRoutes from './privateRoutes';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { checkIsAuth } from '../pages/auth/authUtils';
import { setIsAuth } from '../pages/auth/authSlice';
import { getCookie } from '../utils/cookies';
import { setUserData } from '../redux/slices/userSlice';
import { setCurrOrgId, setUserOrgs } from '../redux/slices/orgSlice';

export default function SiteRoutes() {
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		const isAuth = checkIsAuth();

		dispatch(setIsAuth(isAuth));

		let userData = getCookie('userData');
		let userOrgs = getCookie('userOrgs');
		let newOrgId = null;

		if (isAuth && userData) {
			userData = JSON.parse(userData);
			dispatch(setUserData(userData));


			if (userData.userData.orgId) {
				newOrgId = userData.userData.orgId;
			}
		}

		if (isAuth && userOrgs) {
			userOrgs = JSON.parse(userOrgs);
			dispatch(setUserOrgs(userOrgs));
		}

		const temp = localStorage.getItem('orgId');
		const orgId = temp ? temp : newOrgId;
		dispatch(setCurrOrgId(orgId));
	}, []);

	return (
		<Routes>
			{/* Public routes */}
			<Route path="/login" element={<Login />} />

			{/* Private routes */}
			<Route
				path="/"
				element={
					checkIsAuth() ? <PrivateRoutes /> : <Navigate to="/login" state={{ from: location }} replace />
				}
			>
				<Route path="/org/:orgId">
					<Route path="overview" element={<Overview />} />
					<Route path="assistants" element={<Assistants />} />
					<Route path="call-logs" element={<CallLogs />} />
					<Route path="call-logs/:id" element={<CallLogs />} />
				</Route>
			</Route>

			{/* Fallback route */}
			<Route path="*" element={<Navigate to="/login" />} />
		</Routes>
	);
}
