import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, Typography, Stack, Fade } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { BarGraph, GenericCard, PieGraph, OverviewSkeleton } from './components';
import { fetchGraphData, fetchOverViewSection } from './apiFunction';
import { TRANSITION_TIME_OUT } from '../../utils/constants';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangePopover from '../../components/filters/DateRangePopover';
import { setDateParams } from '../../components/filters/utilFunctions';
import { FILTER_SLICE_KEY } from '../../components/filters/filterSlice';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

export default function Overview() {
	const { orgId } = useParams();

	const [genericCardList, setGenericCardList] = useState([]);
	const [barChartData, setBarChartData] = useState([]);
	const [pieChartData, setPieCharData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);

	const { appliedDateRangeParams, appliedDateRangeString} = useSelector(state => state[FILTER_SLICE_KEY]);

	const onGetSectionDataSuccess = (data) => {
		setGenericCardList(data || []);
		setIsLoading(false);
	};

	const onGetSectionDataError = (error) => {
		toast.error("Error occurred while fetching overview data!");
		console.log(error);
		setIsLoading(false);
	};

	const getSectionData = (dateParams) => {
		setIsLoading(true);

		let params = { ...dateParams };

		fetchOverViewSection(
			params,
			{orgId: orgId},
			onGetSectionDataSuccess,
			onGetSectionDataError
		);
	};

	const onGetGraphDataSuccess = (data) => {
		const { barChartData, pieChartData } = data || {};
		setBarChartData(barChartData || []);
		setPieCharData(pieChartData || []);
		setIsLoading(false);
	};

	const onGetGraphDataError = (error) => {
		toast.error("Error occurred while fetching data!");
		console.log(error);
		setIsLoading(false);
	};

	const getGraphData = (dateParams) => {
		setIsLoading(true);

		let params = { ...dateParams };

		fetchGraphData(
			params,
			{orgId: orgId},
			onGetGraphDataSuccess,
			onGetGraphDataError
		);
	}

	const handleOpenDatePicker = (e) => {
		setAnchorEl(e.currentTarget);
	};

	useEffect(() => {
		const dateParams = setDateParams(appliedDateRangeParams, 'YYYY/MM/DD', 'startDate', 'endDate');
		getSectionData(dateParams);
		getGraphData(dateParams);
	}, [appliedDateRangeParams, orgId]);

	if (isLoading) return <OverviewSkeleton />;

	return (
		<Suspense fallback={<div>Loading</div>}>
			<Box width="100%">
				<Fade
					in={true}
					timeout={TRANSITION_TIME_OUT}
				>
					<Box>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
						>
							<Typography variant='body1'>Display data from</Typography>
							<Button
								sx={{ marginLeft: '8px', backgroundColor: 'white' }}
								size="medium"
								onClick={handleOpenDatePicker}
								variant="outlined"
								endIcon={anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							>
								<Typography 
									variant='body1'
									style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
								>
									{appliedDateRangeString}
								</Typography>
							</Button>
							<DateRangePopover 
								anchorEl={anchorEl}
								closeDatePicker={() => setAnchorEl(null)}
							/>
						</Stack>
					</Box>
				</Fade>

				<Fade
					in={true}
					timeout={TRANSITION_TIME_OUT}
				>
					<Box sx={{ marginTop: '16px' }}>
						<Grid
							container
							columnSpacing={2}
							rowSpacing={2}
						>
							{genericCardList.map((item) => (
								<Grid
									item
									xs={2}
									key={item.title}
								>
									<Box>
										<GenericCard
											title={item.title}
											value={item.value}
										/>
									</Box>
								</Grid>
							))}

							<Grid
								item
								xs={4}
							>
								<Box>
									<GenericCard
										title={""}
										value={""}
									/>
								</Box>
							</Grid>

						</Grid>
					</Box>
				</Fade>

				<Fade
					in={true}
					timeout={TRANSITION_TIME_OUT}
				>
					<Box sx={{ marginTop: '16px' }}>
						<Grid
							container
							columnSpacing={2}
						>
							{barChartData.length !== 0 ? (
								<>
									<Grid
										item
										xs={6}
									>
										<BarGraph data={barChartData} />
									</Grid>
								</>
							) : null}

							{pieChartData.length !== 0 ? (
								<>
									<Grid
										item
										xs={6}
									>
										<PieGraph data={pieChartData} />
									</Grid>
								</>
							) : null}
						</Grid>
					</Box>
				</Fade>
			</Box>
		</Suspense>
	);
}
