import { Box, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Tooltip } from "@mui/material";
import { IconReplace } from "@tabler/icons-react";
import { Popper } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { ORG_SLICE_KEY, setCurrOrgId } from "../../../redux/slices/orgSlice";
import { useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";

export default function UserOrgsPopper() {
    const { orgId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const { userOrgs } = useSelector(state => state[ORG_SLICE_KEY]);
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);

    const chnageOrgBtnRef = useRef(null);

    const handleOrgChange = (e) => {
        const newOrgId = e.target.value;
        dispatch(setCurrOrgId(newOrgId));
        localStorage.setItem("orgId", newOrgId);

        if (newOrgId !== orgId) {
            const updatedPath = location.pathname.replace(orgId, newOrgId);
            navigate(updatedPath);
        }

        setAnchorEl(null);
    }

    return (
        userOrgs && (
            <>
                <Tooltip title={anchorEl ? "" : "Change Organization"} placement='right' arrow>
                    <IconButton 
                        ref={chnageOrgBtnRef}
                        variant="small" 
                        sx={{ marginRight: "-8px"}}
                        onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
                    >
                        <IconReplace size={16} stroke={2} />
                    </IconButton>
                </Tooltip>

                <Popper
                    id="user-orgs-popper"
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    placement={"right-start"}
                    btnRef={chnageOrgBtnRef}
                >
                    <Box p={2}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="user-orgs"
                                name="user-orgs"
                                value={orgId}
                                onChange={handleOrgChange}
                            >
                                {
                                    userOrgs.map((org, index) => (
                                        <FormControlLabel
                                            key={index}
                                            sx={{
                                                margin: "2px -8px",
                                                padding: "0 12px 0 4px",
                                                "&:hover": {
                                                    backgroundColor: "primary.accent",
                                                    borderRadius: "8px"
                                                }
                                            }}
                                            control={
                                                <Radio
                                                    icon={<RadioButtonUnchecked style={{ fontSize: "16px" }} />}   
                                                    checkedIcon={<RadioButtonChecked style={{ fontSize: "16px" }} />}
                                                />
                                            }
                                            name={org.orgName}
                                            label={org.orgName}
                                            value={org.orgId}
                                        />
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Popper>
            </>
        )
    );
}