import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Skeleton, Stack, Tooltip } from '@mui/material';
import { IconChevronLeft, IconChevronRight, IconClockHour3, IconDental, IconHeadset, IconPhone, IconReplace } from '@tabler/icons-react';
import { NAVBAR_HEIGHT } from '../navbar/Navbar';
import { NavLink, useParams } from 'react-router-dom';
import { PmsConnectButton } from '../navbar/components';
import { toast } from 'react-toastify';
import { fetchSidenavConfig } from './apiFunctions';
import "./sideNav.scss";
import UserOrgsPopper from './components/UserOrgsPopper';
import { useSelector } from 'react-redux';
import { ORG_SLICE_KEY } from '../../redux/slices/orgSlice';

const SIDENAV_WIDTH = 240+12;

const openedMixin = (theme) => ({
    width: SIDENAV_WIDTH,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => {
    return ({
            transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(4.5),
    });
};

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        width: SIDENAV_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                },
            },
            {
                props: ({ open }) => !open,
                style: {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                },
            },
        ],
    }),
);

export default function SideNav() {
    const { orgId } = useParams();

    const { userOrgs } = useSelector(state => state[ORG_SLICE_KEY]);

    const [openSidenav, setOpenSidenav] = useState(true);
    const [sidenavConfig, setSidenavConfig] = useState(null);
    const [isLoadingConfig, setIsLoadingConfig] = useState(false);
    const [currOrgName, setCurrOrgName] = useState(null);

    const onGetSidenavConfigSuccess = (data) => {
        setSidenavConfig(data);
        setIsLoadingConfig(false);
    };

    const onGetSidenavConfigError = (error) => {
        toast.error("Error occurred while fetching sidenav config!");
        setIsLoadingConfig(false);
    };

    const getSidenavConfig = (id) => {
        setIsLoadingConfig(true);

        fetchSidenavConfig(
            {},
            {orgId: id},
            onGetSidenavConfigSuccess,
            onGetSidenavConfigError
        )
    };

    useEffect(() => {
        getSidenavConfig(orgId);
    }, [orgId]);

    useEffect(() => {
        if (userOrgs) {
            const currOrg = userOrgs.find(org => org.orgId === orgId);
            setCurrOrgName(currOrg.orgName);
        }
    }, [userOrgs, orgId]);

    return (
        <Drawer 
            id="sidenav"
            variant="permanent" 
            anchor="left"
            open={openSidenav}
            sx={{
                "& .MuiDrawer-paper": {
                    backgroundColor: "grey.contentBg",
                    height:`calc(100vh - ${NAVBAR_HEIGHT})`,
                    border: "none",
                    top: "auto",
                    bottom: 0,
                },
                "&:hover": {
                    ".sidenav-toggle": {
                        opacity: 1,
                    }
                }
            }}
        >
            <Stack direction="row" alignItems="flex-start">
                <Box
                    sx={{
                        backgroundColor: "white",
                        height: `calc(100vh - ${NAVBAR_HEIGHT})`,
                        paddingTop: "16px",
                        borderRight: "1px solid",
                        borderColor: "grey.200",
                        flexGrow: 1,
                    }}
                >
                    {
                        openSidenav && (
                            <>
                                <List 
                                    aria-labelledby="organization"
                                    width="100%"
                                    sx={{
                                        backgroundColor: "transparent",
                                    }}
                                >
                                    {/* organization */}
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar 
                                                variant="rounded"
                                                sx={{ height: "32px", width: "32px", bgcolor: "primary.main" }}
                                            >
                                                <IconDental size={24} stroke={2} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Tooltip title={currOrgName} followCursor>
                                            <ListItemText
                                                primary={currOrgName} 
                                                secondary="Organization" 
                                                sx={{
                                                    "& .MuiListItemText-primary": {
                                                        maxWidth: "140px",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis",
                                                    }
                                                }}
                                            />
                                        </Tooltip>
                                        <UserOrgsPopper />
                                    </ListItem>
                                    <ListItem>
                                        <PmsConnectButton />
                                    </ListItem>
                                </List>

                                <Divider 
                                    variant="middle"  
                                    sx={{
                                        marginTop: "16px",
                                        marginBottom: "20px",
                                        opacity: 0.5,
                                    }}
                                />

                                {/* platform */}
                                <List
                                    sx={{
                                        marginTop: "16px",
                                    }}
                                    subheader={
                                        <ListSubheader 
                                            id="organization"
                                            className='sidenav-subheader'
                                            component="div" 
                                        >
                                            Platform
                                        </ListSubheader>
                                    }
                                >
                                    {
                                        sidenavConfig && !isLoadingConfig ? (
                                            sidenavConfig.length > 0 && (
                                                sidenavConfig.map((tab, index) => {
                                                    const tabIcons = [
                                                        <IconClockHour3 size={20} stroke={2} />,
                                                        <IconHeadset size={20} stroke={2} />,
                                                        <IconPhone size={20} stroke={2} />
                                                    ];

                                                    return (
                                                        <NavLink
                                                            key={tab.tabId}
                                                            className={`sidenav-tab-link ${window.location.pathname === "/" && tab.tabName === "Overview" ? "active" : ""}`}
                                                            to={`/org/${orgId}${tab.tabUrl}`}
                                                        >
                                                            <ListItemButton className="sidenav-tab">
                                                                <ListItemIcon>
                                                                    {tabIcons[index]}
                                                                </ListItemIcon>
                                                                <ListItemText primary={tab.tabName} />
                                                            </ListItemButton>
                                                        </NavLink>
                                                    );
                                                })
                                            )
                                        ) : (
                                            new Array(3).fill(null).map((_, index) => (
                                                <Stack
                                                    key={index}
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="flex-start"
                                                    spacing={2}
                                                    sx={{ height: "52px", margin: "0 16px" }}
                                                >
                                                    <Skeleton
                                                        variant="rectangular"
                                                        width={24}
                                                        height={24}
                                                    />
                                                    <Skeleton
                                                        variant="text"
                                                        width="100%"
                                                        height={24}
                                                    />
                                                </Stack>
                                            ))
                                        )
                                    }
                                </List>
                            </>
                        )
                    }
                </Box>
                <IconButton 
                    className='sidenav-toggle'
                    size="small"
                    sx={{
                        backgroundColor: "white",
                        border: "1px solid",
                        borderColor: "grey.300",
                        marginTop: "16px",
                        marginLeft: "-13px",
                        transition: "all 0.4s ease",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        opacity: openSidenav ? 0 : 1,
                        "&:hover": {
                            backgroundColor: "primary.main",
                            color: "white",
                        }
                    }}
                    onClick={() => setOpenSidenav(!openSidenav)}
                >
                    { openSidenav ? <IconChevronLeft size={12} stroke={4} /> : <IconChevronRight size={12} stroke={4} />}
                </IconButton>
            </Stack>
        </Drawer>
    );
}
