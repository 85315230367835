import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { getConsumerToken, getOrgDetails, updateOrgDetails, validateKollaIntegration } from "../apiFunction";
import { toast } from "react-toastify";
import { IconChecks, IconCirclesRelation } from "@tabler/icons-react";
import { Popper } from "../..";

export default function PmsConnectButton() {
    const { orgId } = useParams();

    const [isConnecting, setIsConnecting] = useState(false);
    const [refreshOrgDetails, setRefreshOrgDetails] = useState(false);
    const [pmsDetails, setPmsDetails] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    const pmsConnectBtnRef = useRef(null);

    const kolla = window.kolla;

    // const onValidateKollaIntegrationSuccess = (data) => {
    //     if (data.status === true) {
    //         toast.success("Dental PMS is connected successfully.");
    //         setRefreshOrgDetails(true);
    //     } 
    // }

    const onGetConsumerTokenSuccess = (data) => {
        kolla.authenticate(data.token);
        kolla.openMarketplace();

        kolla.subscribe("onMarketplaceClosed", () => {
            setRefreshOrgDetails(true);
            setIsConnecting(false);
        });
    }

    const onGetConsumerTokenError = (error) => {
        toast.error("Error connecting Dental PMS. Please try again later.");
        console.log(error);
        setIsConnecting(false);
    }

    const handleConnect = () => {
        setIsConnecting(true);

        getConsumerToken({ orgId: orgId }, onGetConsumerTokenSuccess, onGetConsumerTokenError);
    }

    useEffect(() => {
        getOrgDetails(
            orgId, 
            (data)=>{setPmsDetails(data?.organization?.pmsDetails || {})}, 
            (error)=>{console.log("ERROR", error);}
        );
    }, [orgId, refreshOrgDetails]);

    return (
        <>
            {
                Object.keys(pmsDetails).length > 0 ? (
                    <Button 
                        ref={pmsConnectBtnRef}
                        fullWidth
                        variant="outlined"
                        color="success"
                        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                        onMouseLeave={() => setAnchorEl(null)}
                        // onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
                    >
                        <IconChecks size={20} stroke={2}/>
                        <>PMS Connected</>
                    </Button>
                ) : (
                    <Button 
                        fullWidth
                        variant="outlined"
                        onClick={ handleConnect }
                        disabled={ isConnecting }
                    >
                        <IconCirclesRelation size={20} stroke={2}/>
                        {
                            isConnecting ? (
                                <><CircularProgress size={16} /> Connecting...</>
                            ) : (
                                "Connect Dental PMS"
                            )
                        }
                    </Button>
                )
            }
            
            {
                Object.keys(pmsDetails).length > 0 && (
                    <Popper
                        id="pms-details-popper"
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        placement="right-start"
                        btnRef={pmsConnectBtnRef}
                    >
                        <Stack spacing={1} direction="column" p={2}>
                            {pmsDetails.pmsIntegrated && <Typography fontSize={"14px"} color="grey.700"><b>PMS Integrated: </b> {pmsDetails.pmsIntegrated}</Typography>}
                            {pmsDetails.pmsType && <Typography fontSize={"14px"} color="grey.700"><b>PMS Type: </b> {pmsDetails.pmsType}</Typography>}
                            {pmsDetails.integratedOn && <Typography fontSize={"14px"} color="grey.700"><b>Integrated On: </b> {pmsDetails.integratedOn}</Typography>}
                            {pmsDetails.integratedBy && <Typography fontSize={"14px"} color="grey.700"><b>Integrated By: </b> {pmsDetails.integratedBy}</Typography>}
                        </Stack>
                    </Popper>
                )
            }
        </>
    );
}