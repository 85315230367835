import { Popper as MuiPopper, Paper } from "@mui/material";
import { useEffect, useRef } from "react";

export default function Popper({id, anchorEl, setAnchorEl, placement, btnRef, children}) {
    const popperRef = useRef(null);

    const handleCloseOnOutsideClick = (event) => {
        if (popperRef.current && !popperRef.current.contains(event.target) && btnRef.current && !btnRef.current.contains(event.target)) {
            setAnchorEl(null);
        }
    }

    useEffect(() => {
        if (anchorEl) {
            document.addEventListener('mousedown', handleCloseOnOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleCloseOnOutsideClick);
        }

        return () => document.removeEventListener('mousedown', handleCloseOnOutsideClick);
    }, [anchorEl]);

    return (
        <MuiPopper
            id={id}
            ref={popperRef}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement={placement}
            disablePortal={false}
            modifiers={[
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        boundary: 'document',
                        rootBoundary: 'document',
                        altAxis: true, 
                        tether: false, 
                        padding: 8,
                    },
                    },
                    {
                    name: 'flip',
                    enabled: false, 
                },
            ]}
            sx={{ zIndex: (theme) => theme.zIndex.tooltip }}
        >
            <Paper
                elevation={3}
                sx={{
                    borderRadius: 2,
                    margin: 0.5,
                }}
            >
                {children}
            </Paper>
        </MuiPopper>
    );
}