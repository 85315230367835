import Request from '../../utils/api';
import { OVERVIEW_SECTION_1, OVERVIEW_SECTION_2 } from '../../utils/apiRoutes';

const api = new Request();

export const fetchOverViewSection = async (params, headers, onSuccess, onError) => {
	const response = await api.get(OVERVIEW_SECTION_1, params, headers);

	if (response.message === "Success" && response.data) {
		return typeof onSuccess === "function" ? onSuccess(response.data) : response.data;
	} else {
		return typeof onError === "function" ? onError(response) : response;
	}
};
export const fetchGraphData = async (params, headers, onSuccess, onError) => {
	const response = await api.get(OVERVIEW_SECTION_2, params, headers);

	if (response.message === "Success" && response.data) {
		return typeof onSuccess === "function" ? onSuccess(response.data) : response.data;
	} else {
		return typeof onError === "function" ? onError(response) : response;
	}
};
