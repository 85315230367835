import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { resetAuthState } from "../../../pages/auth/authSlice";
import { USER_SLICE_KEY } from "../../../redux/slices/userSlice";
import { removeCookie } from "../../../utils/cookies";
import { stringToColor } from "../../../utils/common";
import { Popper } from "../..";
import { IconLogout } from "@tabler/icons-react";

export default function ProfilePopper() {
    const navigate = useNavigate();

    const { userInfo } = useSelector(state => state[USER_SLICE_KEY]);
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);

    const profileBtnRef = useRef(null);

    const handleLogout = () => {
        removeCookie("accessToken");
        removeCookie("userData");
        removeCookie("userOrgs");
        localStorage.removeItem("auth-mode");
        dispatch(resetAuthState());
        toast.success("Logged out!");
        navigate("/login"); 
    }

    return (
        <>
            {/* anchorEl */}
            <IconButton 
                ref={profileBtnRef}
                aria-describedby="profile-popper"
                size="small"
                sx={{ backgroundColor: anchorEl ? "grey.200" : "transparent" }}
                onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
            >
                <Tooltip title={anchorEl ? "" : "Your Profile"} arrow>
                    <Avatar 
                        sx={{ 
                            bgcolor: userInfo?.firstName || userInfo?.lastName ? stringToColor(userInfo.firstName ? userInfo.firstName : userInfo.lastName) : "grey.400",
                            fontSize: "12px",
                            height: "28px", 
                            width: "28px" 
                        }}
                        children={userInfo?.firstName || userInfo?.lastName ? `${userInfo.firstName && userInfo.firstName[0]}${userInfo.lastName && userInfo.lastName[0]}` : ""}
                    />
                </Tooltip>
            </IconButton>

            {/* popperEl */}
            <Popper
                id="profile-popper"
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                placement={"bottom-end"}
                btnRef={profileBtnRef}
            >
                <Box sx={{ maxWidth: "320px", minWidth: "240px", paddingTop: "16px"}}>
                    {/* account */}
                    <List 
                        aria-labelledby="account"
                        subheader={
                            <ListSubheader 
                                id="account"
                                component="div" 
                                sx={{ borderRadius: 2 }}
                            >
                                Account
                            </ListSubheader>
                        }
                        width="100%"
                    >
                        <ListItem sx={{ display: "flex"}}>
                            <ListItemAvatar>
                                <Avatar 
                                    sx={{ 
                                        bgcolor: userInfo?.firstName || userInfo?.lastName ? stringToColor(userInfo.firstName ? userInfo.firstName : userInfo.lastName) : "grey.400",
                                        fontSize: "14px",
                                        height: "32px", 
                                        width: "32px" 
                                    }}
                                    children={userInfo?.firstName || userInfo?.lastName ? `${userInfo.firstName && userInfo.firstName[0]}${userInfo.lastName && userInfo.lastName[0]}` : ""}
                                />
                            </ListItemAvatar>
                            <ListItemText 
                                primary={`${userInfo?.firstName && userInfo.firstName} ${userInfo?.lastName && userInfo.lastName}`} 
                                secondary={userInfo?.phone ? `${userInfo?.phoneCountryCode && ("+" + userInfo.phoneCountryCode)} ${userInfo.phone}` : `${userInfo?.email}`} 
                            />
                        </ListItem>
                    </List>

                    <Divider />

                    {/* logout */}
                    <List width="100%" >
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleLogout}>
                                <ListItemIcon>
                                    <IconLogout size={20} stroke={2} />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Popper>
        </>
    )
}