import Request from '../../utils/api';
import { SIDENAV_CONFIG } from '../../utils/apiRoutes';

const api = new Request();

export const fetchSidenavConfig = async (params, headers, onSuccess, onError) => {
    const response = await api.get(SIDENAV_CONFIG, params, headers);

    if (response.data && response.message === "Success") {
        return typeof onSuccess === "function" ? onSuccess(response.data) : response.data;
    } else {
        return typeof onError === "function" ? onError(response.data) : response.data;
    }
};
