import { Navigate, Outlet, useLocation } from "react-router";
import { Box } from "@mui/material";
import { Navbar, SideNav } from "../components";
import { NAVBAR_HEIGHT } from "../components/navbar/Navbar";

export const OUTLET_HEIGHT = `calc(100vh - ${NAVBAR_HEIGHT} - 32px - 16px)`;

export default function PrivateRoutes() {
    const location = useLocation();

    return (
        location.pathname === "/" ? 
        <Navigate to="/login" replace /> :
        <main>
            <Navbar />
            <Box display={"flex"}>
                <SideNav />
                <Box 
                    flexGrow={1} 
                    display={"flex"} 
                    flexDirection={"column"}
                    sx={{
                        backgroundColor: "grey.contentBg",
                        height: `calc(100vh - ${NAVBAR_HEIGHT})`,
                        padding: "32px 16px 16px",
                        overflowY: "auto",
                    }}
                >
                    <Outlet />
                </Box>
            </Box>
        </main>
    )
}