import { createSlice } from "@reduxjs/toolkit";

export const USER_SLICE_KEY = "user";

export const userSlice = createSlice({
    name: USER_SLICE_KEY,
    initialState: {
        orgId: null,
        userData: null,
        userId: null,
        userInfo: null,
    },
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload;
            state.userId = action.payload?.userId ? action.payload.userId : null;
            state.userInfo = action.payload?.userData ? action.payload.userData : null;
        },
    },
});

export const { setUserData } = userSlice.actions;
export default userSlice.reducer;