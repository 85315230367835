import { Box, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { Popper } from "../.."
import { useRef, useState } from 'react';
import { copyTextToClipboard } from '../../../utils/common';
import { IconCopy, IconCopyCheck } from '@tabler/icons-react';
import { HelpRounded } from '@mui/icons-material';

export default function SupportPopper () {
    const SUPPORT_MAIL = "thirdvoice.ai@gmail.com";

    const [anchorEl, setAnchorEl] = useState(null);
    const [emailCopied, setEmailCopied] = useState({ entity: "email", copied: false });

    const supportBtnRef = useRef(null);

    return (
        <>
            {/* anchorEl */}
            <IconButton 
                ref={supportBtnRef}
                aria-describedby="support-popper"
                size="small"
                sx={{ backgroundColor: anchorEl ? "grey.200" : "transparent" }}
                onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
            >
                <Tooltip title={anchorEl ? "" : "Support"} arrow>
                    <HelpRounded sx={{ color: "grey.500", height: "28px", width: "28px"}}/>
                </Tooltip>
            </IconButton>
            
            {/* popperEl */}
            <Popper 
                id="support-popper"
                anchorEl={anchorEl} 
                setAnchorEl={setAnchorEl}
                placement={"bottom-end"}
                btnRef={supportBtnRef}
            >
                <Box p={2}>
                    <Typography variant='body1' color="text.secondary" textAlign='center'>Connect with us at</Typography>
                    <Stack direction='row' alignItems='center' justifyContent='center' spacing={1} my={1}>
                        <Link href={`mailto:${SUPPORT_MAIL}`} underline='hover'>
                            <Typography variant='body1' textAlign='center' color='primary'>{SUPPORT_MAIL}</Typography>
                        </Link>
                        <Tooltip title={emailCopied.copied ? "Email copied!" : "Copy email"} arrow>
                            <IconButton 
                                sx={{ padding: "4px", marginLeft: "4px" }}
                                onClick={() => copyTextToClipboard(SUPPORT_MAIL, setEmailCopied, "email")}
                            >
                                {
                                    emailCopied.copied ? 
                                    <IconCopyCheck size={14} stroke={1.5} /> :
                                    <IconCopy size={14} stroke={1.5} />
                                }
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Typography variant='body1' color="text.secondary" textAlign='center'>and we will find out the best</Typography>
                    <Typography variant='body1' color="text.secondary" textAlign='center'>way to help you.</Typography>
                </Box>
            </Popper>
        </>
    );
}